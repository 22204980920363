import React, { FC, useContext, useState } from "react";
import * as styles from "./CaseStudy.module.scss";
import { Link } from "gatsby";
import CircleBlur from "../Shared/CircleOutlineAndBlur/CircleBlur";
import CircleOutline from "../Shared/CircleOutlineAndBlur/CircleOutline";
import buttonRight from "../../images/buttonArrowRight.svg";
import filter from "../../images/filter.svg";
import { GatsbyImage } from "gatsby-plugin-image";
import PagePagination from "../Shared/PagePagination/PagePagination";
import InnerWrapper from "../Shared/InnerWrapper/InnerWrapper";
import { UseImageUrl } from "../hooks/UseImageUrl";
import { UseWindowSize } from "../hooks/UseWindowSize";
import MobileModal from "../Shared/MobileModal/MobileModal";
import CircleGreenBlur from "../Shared/CircleOutlineAndBlur/Circlegreenblur";
import { LanguageContext } from "../../context/languageContext";
import translate from "../../i18n/translate";
import { CaseStudiesPageContextType } from "./CaseStudies.helper";
import { BLOG_ACTIVE_COLOR } from "../BlogUiComponents/BlogsMain/BlogsMain.helper";

type Props = {
  data: any;
  pageContext: CaseStudiesPageContextType;
};

const CaseStudyMain: FC<Props> = ({ data, pageContext }) => {
  const { language } = useContext(LanguageContext);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const { width } = UseWindowSize();

  return (
    <div className={styles.caseStudiesContainerWrapper}>
      <div className={styles.caseStudiesMainContainer}>
        <h1 className={styles.caseStudiesMainContainerHead}>
          <span>.</span>
          {translate("home-caseStudies")}
        </h1>
        <p>{translate("case-studies-heading")}</p>
        <div className={styles.backgroundCircles}>
          <CircleOutline
            isBlurVisible={false}
            initialScale={0.3}
            animateScale={1}
            transitionType={"easeIn"}
            transitionDelay={0.2}
            transitionDuration={1}
            circleStyleClass={styles.contactOutlineCircle}
          />
          <CircleGreenBlur
            transitionType={"easeIn"}
            transitionDelay={0.2}
            transitionDuration={1}
            circleStyleClass={styles.aboutMainCircleBlurGreen}
          />

          <CircleBlur
            initialOpacity={0}
            animateOpacity={0.1}
            transitionType={"easeIn"}
            transitionDelay={0.2}
            transitionDuration={1}
            circleStyleClass={styles.aboutMainCircleBlurBlue}
          />
        </div>
      </div>

      <div className={styles.caseStudiesMainTags}>
        {width < 771 ? (
          <>
            <div
              style={{ zIndex: 999999999 }}
              className={styles.filterActionButton}
              onClick={() => setIsFilterOpen(true)}
            >
              <span>filter</span>
              <img src={filter} alt="filter" />
            </div>

            <div
              style={{
                visibility: isFilterOpen ? "visible" : "hidden",
              }}
              className={styles.blogModalWrapper}
            >
              <MobileModal
                setIsModalHidden={setIsFilterOpen}
                modalContent={"blog"}
                basePathForUrl={"/case-study"}
                blogTagClass={styles.blogTag}
                blogWrapperClass={styles.blogTagsWrapper}
                tagsFromBlog={data.allSanityCaseStudyTag.edges
                  .filter(({ node }: any) =>
                    pageContext.tags.includes(node.name)
                  )
                  .map(({ node }: any) => ({ title: node.name }))}
              />
            </div>
          </>
        ) : (
          <>
            <div className={styles.caseStudiesTag}>
              <Link
                activeStyle={{
                  color: BLOG_ACTIVE_COLOR,
                  borderBottom: `1px solid ${BLOG_ACTIVE_COLOR}`,
                }}
                to="/case-study"
              >
                All
              </Link>
            </div>
            {pageContext.tags &&
              pageContext.tags.map((tag: string, index: number) => (
                <div key={index} className={styles.caseStudiesTag}>
                  <Link
                    activeStyle={{
                      color: BLOG_ACTIVE_COLOR,
                      borderBottom: `1px solid ${BLOG_ACTIVE_COLOR}`,
                    }}
                    to={`/case-study/${tag.toLowerCase()}`}
                  >
                    {tag}
                  </Link>
                </div>
              ))}
          </>
        )}
      </div>
      <InnerWrapper>
        <div className={styles.caseStudiesMainArticles}>
          <div className={styles.caseStudiesRow}>
            {data.allSanityCaseStudy.edges.map(
              ({ node }: any, index: number) => {
                UseImageUrl(node.mainImage, node._rawMainImage);
                return (
                  <div
                    key={index}
                    className={
                      index % 2 === 0 &&
                      data.allSanityCaseStudy.edges.length > 1
                        ? styles.caseStudiesColumnLeft
                        : styles.caseStudiesColumnRight
                    }
                  >
                    <Link
                      to={`/case-study/${node.title
                        .toString()
                        .toLowerCase()
                        .replace(/\s+/g, "-")
                        .replace(/[^\w-]+/g, "")
                        .replace(/--+/g, "-")
                        .replace(/^-+/, "")
                        .replace(/-+$/, "")
                        .toLowerCase()}`}
                    >
                      <GatsbyImage
                        image={node.mainImage.asset.gatsbyImageData}
                        alt={node?.altMainImage || "CaseStudy"}
                        className={styles.casePreviewImage}
                      />
                      <h3>
                        .{language === "EN" ? node?.title : node?.titleDE}
                      </h3>
                      <p>
                        {language === "EN"
                          ? node?.caseStudyDescription[0].children[0].text
                          : node?.caseStudyDescriptionDe[0].children[0].text}
                      </p>
                      <span>read more</span>&nbsp;&nbsp;
                      <img src={buttonRight} alt="Right Button" />
                    </Link>
                  </div>
                );
              }
            )}
          </div>
        </div>

        {pageContext.numberOfPages > 1 && (
          <PagePagination
            pageNumber={pageContext.numberOfPages}
            currentPage={pageContext.currentPage}
            basePathForUrl="/case-study"
          />
        )}
      </InnerWrapper>
    </div>
  );
};

export default CaseStudyMain;
