// extracted by mini-css-extract-plugin
export var aboutMainCircleBlurBlue = "CaseStudy-module--aboutMainCircleBlurBlue--a2625";
export var aboutMainCircleBlurGreen = "CaseStudy-module--aboutMainCircleBlurGreen--93f28";
export var approach = "CaseStudy-module--approach--c9928";
export var automate = "CaseStudy-module--automate--08d25";
export var automatebenifit = "CaseStudy-module--automatebenifit--5ac99";
export var backgroundCircles = "CaseStudy-module--backgroundCircles--84798";
export var backgroundCirclesBlogArticles = "CaseStudy-module--backgroundCirclesBlogArticles--18289";
export var benefit = "CaseStudy-module--benefit--92aeb";
export var blogModalNav = "CaseStudy-module--blogModalNav--14d39";
export var blogModalWrapper = "CaseStudy-module--blogModalWrapper--6babc";
export var blogTag = "CaseStudy-module--blogTag--311e2";
export var blogTagsWrapper = "CaseStudy-module--blogTagsWrapper--04567";
export var blogsCircleBlueRight = "CaseStudy-module--blogsCircleBlueRight--41f3d";
export var casePreviewImage = "CaseStudy-module--casePreviewImage--780fc";
export var caseStudiesColumnLeft = "CaseStudy-module--caseStudiesColumnLeft--e0833";
export var caseStudiesColumnRight = "CaseStudy-module--caseStudiesColumnRight--01b08";
export var caseStudiesContainerWrapper = "CaseStudy-module--caseStudiesContainerWrapper--1a570";
export var caseStudiesMainArticles = "CaseStudy-module--caseStudiesMainArticles--b6fc8";
export var caseStudiesMainContainer = "CaseStudy-module--caseStudiesMainContainer--ab47d";
export var caseStudiesMainContainerHead = "CaseStudy-module--caseStudiesMainContainerHead--88c77";
export var caseStudiesMainTags = "CaseStudy-module--caseStudiesMainTags--66f7b";
export var caseStudiesRow = "CaseStudy-module--caseStudiesRow--54056";
export var caseStudiesTag = "CaseStudy-module--caseStudiesTag--0d1f4";
export var casestudy = "CaseStudy-module--casestudy--b9873";
export var cloudinside = "CaseStudy-module--cloudinside--f7a72";
export var contact = "CaseStudy-module--contact--0dbe6";
export var contactButton = "CaseStudy-module--contactButton--38bce";
export var contactCheckBox = "CaseStudy-module--contactCheckBox--e810b";
export var contactConfirmLabel = "CaseStudy-module--contactConfirmLabel--c91b3";
export var contactCountry = "CaseStudy-module--contactCountry--6fe7a";
export var contactEmail = "CaseStudy-module--contactEmail--1bab9";
export var contactFormAddress = "CaseStudy-module--contactFormAddress--5ee35";
export var contactFormAddressDiv = "CaseStudy-module--contactFormAddressDiv--1c7bf";
export var contactFormAddressItem = "CaseStudy-module--contactFormAddressItem--5ba3d";
export var contactFormAndAddressContainer = "CaseStudy-module--contactFormAndAddressContainer--c0149";
export var contactFormContainer = "CaseStudy-module--contactFormContainer--34187";
export var contactFormInputs = "CaseStudy-module--contactFormInputs--bf83a";
export var contactFormWrapper = "CaseStudy-module--contactFormWrapper--b949f";
export var contactOutlineCircle = "CaseStudy-module--contactOutlineCircle--62280";
export var contactPhoneAndEmail = "CaseStudy-module--contactPhoneAndEmail--c452c";
export var contactTabsCountry = "CaseStudy-module--contactTabsCountry--d45dc";
export var contactUs = "CaseStudy-module--contactUs--a2f2b";
export var contactUsForm = "CaseStudy-module--contactUsForm--c529d";
export var contactUsHeader = "CaseStudy-module--contactUsHeader--1a678";
export var contacted = "CaseStudy-module--contacted--13be7";
export var contacts = "CaseStudy-module--contacts--4ffbb";
export var developments = "CaseStudy-module--developments--bf0cb";
export var fadeInBottomLeft = "CaseStudy-module--fadeInBottomLeft--afcbf";
export var fadeInBottomRight = "CaseStudy-module--fadeInBottomRight--d69ab";
export var filterActionButton = "CaseStudy-module--filterActionButton--c19e3";
export var head = "CaseStudy-module--head--b3d63";
export var heroBackgroundInnerCircleLeft = "CaseStudy-module--heroBackgroundInnerCircleLeft--6077e";
export var inside = "CaseStudy-module--inside--4f0bb";
export var knowUsImageAutomate = "CaseStudy-module--knowUsImageAutomate--51364";
export var knowUsTextAutomate = "CaseStudy-module--knowUsTextAutomate--9846a";
export var threecolumn = "CaseStudy-module--threecolumn--4200e";
export var topBanner = "CaseStudy-module--topBanner--1640e";
export var twocolumn = "CaseStudy-module--twocolumn--4b2d6";